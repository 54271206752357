import './App.css';
import Home from './pages/index';
import Contact from './pages/ContactPage'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Navbar from './components/Navbar';
import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  * {
    --yellow-text: #fdc84b;
    --yellow-background: #fdc84b;
    --color-primary: rebeccapurple;
  }
`;

function App({ children }) {
  return (
    <Router>
      <Switch>
        <Route path="/" component={Home} exact />
        <Route path="/contact" component={Contact} exact />
      </Switch>
      {/* <GlobalStyles />
      {children}
      <Navbar />
      <Home /> */}
    </Router>
  );
}

export default App;

import React from 'react'
import {
    SidebarContainer,
    Icon,
    CloseIcon,
    SidebarLink,
    SidebarMenu,
    SidebarWrapper,
    SidebarRoute,
    SideBtnWrap
} from './SidebarElements'

const Sidebar = ({ isOpen, toggleIsOpen }) => {
    return (
        <SidebarContainer isOpen={isOpen} onClick={toggleIsOpen}>
            <Icon onClick={toggleIsOpen}>
                <CloseIcon />
            </Icon>
            <SidebarWrapper>
                <SidebarMenu>
                    <SidebarLink to='about'
                        smooth={true}
                        duration={500}
                        spy={true}
                        exact='true'
                        offset={-80} onClick={toggleIsOpen}>
                        Om oss
                    </SidebarLink>
                    <SidebarLink to='analys'
                        smooth={true}
                        duration={500}
                        spy={true}
                        exact='true'
                        offset={-80} onClick={toggleIsOpen}>
                        Analys
                    </SidebarLink>
                    <SidebarLink to='services'
                        smooth={true}
                        duration={500}
                        spy={true}
                        exact='true'
                        offset={-70} onClick={toggleIsOpen}>
                        Tjänster
                    </SidebarLink>
                </SidebarMenu>
                <SideBtnWrap>
                    <SidebarRoute to='/contact'>Kontakta oss</SidebarRoute>
                </SideBtnWrap>
            </SidebarWrapper>
        </SidebarContainer>
    )
}

export default Sidebar

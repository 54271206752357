import styled from 'styled-components'
import { Link } from 'react-router-dom'

// Längst nern all rights reserved

export const SocialMediaContainer = styled.section`
   background-color: #010606;
   height: 80px;
   // max-width: 1100px;
`

export const SocialMediaWrap = styled.section`
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 400px;
    height: 100%;
    margin: 0 auto 0 auto;

    @media screen and (max-width: 820px) {
        display: flex;
        flex-direction: row;
        align-items: center;
    }
`

export const SocialLogo = styled(Link)`
    color: #fff;
    justify-self: start;
    cursor: pointer;
    text-decoration: none;
    font-size: 1.5rem;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    margin-right: 10px;
    font-weight: bold;
`

export const WebSiteRights = styled.small`
    color: #fff;
    margin-bottom: 10px;
`

export const SocialIcons = styled.div`
    display: flex;
    align-items: center;
    max-width: 80px;
    margin-left: 10px;
`

export const SocialLink = styled.a`
    color: #fff;
    font-size: 24px;
`
import React from 'react'
import { animateScroll as scroll } from 'react-scroll';
import {
    SocialMediaContainer,
    SocialMediaWrap,
    SocialLogo,
    WebSiteRights,
    SocialIcons,
    SocialLink

} from './SocialMediaElements'
import { NavLogo } from '../Navbar/NavbarElements'
import logo from '../../images/logo_1.png';
import { FaLinkedin } from 'react-icons/fa';

function SocialMedia() {
    const toggleHome = () => {
        scroll.scrollToTop();
    }
    return (
        <>
            <SocialMediaContainer>
                <SocialMediaWrap>
                    <SocialIcons>
                        <SocialLink href='/' target='blank' aria-label='LinkedIn'><FaLinkedin /></SocialLink>
                    </SocialIcons>
                    <WebSiteRights>Aimsoft Utveckling AB © {new Date().getFullYear()}
                    </WebSiteRights>
                    <SocialLogo to='/' onClick={toggleHome}>
                        <NavLogo to='/'>
                            <img src={logo} alt="Aimsoft" />
                        </NavLogo>
                    </SocialLogo>
                </SocialMediaWrap>
            </SocialMediaContainer>
        </>
    )
}

export default SocialMedia

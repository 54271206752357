import React from 'react'
import { NavLogo } from '../Navbar/NavbarElements'
import logo from '../../images/logo-180x100.png';
import {
    Container,
    FormContent,
    FormWrap,
    Icon,
    Form,
    FormH1,
    FormLabel,
    FormInput
} from './ContactElements'

const Contact = () => {
    return (
        <>
            <Container>
                <Icon to='/'>
                    <NavLogo to='/'>
                        <img src={logo} alt="Aimsoft" />
                    </NavLogo>
                </Icon>
                <FormWrap>
                    <FormContent>
                        <Form action='#'>
                            <FormH1>Ansök om utvecklare</FormH1>
                            <FormLabel htmlFor='for'>Förnamn</FormLabel>
                            <FormInput type='text'></FormInput>
                            <FormLabel htmlFor='for'>Efternamn</FormLabel>
                            <FormInput type='text'></FormInput>
                            <FormLabel htmlFor='for'>E-post</FormLabel>
                            <FormInput type='email'></FormInput>
                            <FormLabel htmlFor='for'>Beskrivning av uppdrag</FormLabel>
                            <FormInput type='text'></FormInput>
                        </Form>
                    </FormContent>
                </FormWrap>
            </Container>

        </>
    )
}

export default Contact

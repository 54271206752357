import React from 'react'
import {
    FooterContainer,
    FooterWrap,
    FooterLinksContainer,
    FooterLinksWrapper,
    FooterLinkItems,
    FooterLinkTitle,
    FooterLink

} from './FooterElements'

const Footer = () => {
    return (
        <>
            <FooterContainer>
                <FooterWrap>
                    <FooterLinksContainer>
                        <FooterLinksWrapper>
                            <FooterLinkItems>
                                <FooterLinkTitle>Aimsoft development</FooterLinkTitle>
                                <FooterLink to='/'>ANNA STENMARK, VD</FooterLink>
                                <FooterLink to='/'>anna.stenmark@aimsoft.se</FooterLink>
                                <FooterLink to='/'></FooterLink>
                                <FooterLink to='/'>Mobil: 0702 - 89 50 50</FooterLink>
                            </FooterLinkItems>
                            <FooterLinkItems>
                                <FooterLinkTitle>Våra tjänster</FooterLinkTitle>
                                <FooterLink to='/'>Systemutveckling</FooterLink>
                                <FooterLink to='/'>Arkitektur</FooterLink>
                                <FooterLink to='/'>IT-ledning</FooterLink>
                                <FooterLink to='/'>Test</FooterLink>
                            </FooterLinkItems>
                        </FooterLinksWrapper>
                    </FooterLinksContainer>
                </FooterWrap>
            </FooterContainer>
        </>
    )
}

export default Footer

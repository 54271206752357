export const homeObjOne = {
    id: 'about',
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Du får kompetens och erfarenhet genom hela utvecklingskedjan.',
    headLine: 'Från analys och tjänstedesign till systemutveckling och IT-styrning',
    description: 'Med över 20 års erfarenhet av systemutveckling hjälper vi dig att utveckla skräddarsydda och stabila systemlösningar',
    buttonLabel: 'Kontakta oss',
    imgStart: false,
    img: require('../../images/svg1.svg').default,
    alt: 'Street',
    dark: true,
    primary: true,
    darkText: false
}

export const homeObjTwo = {
    id: 'analys',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Du får kompetens och erfarenhet genom hela utvecklingskedjan.',
    headLine: 'Från analys och tjänstedesign till systemutveckling och IT-styrning',
    description: 'Med över 20 års erfarenhet av systemutveckling hjälper vi dig att utveckla skräddarsydda och stabila systemlösningar',
    buttonLabel: 'Kontakta oss',
    imgStart: true,
    img: require('../../images/svg4.svg').default,
    alt: 'Street',
    dark: true,
    primary: true,
    darkText: true
}
import React from 'react'
import Slider from 'react-elastic-carousel';
import { Card } from './CarouselElements';

const responsive = [
    { width: 1, itemsToShow: 1 },
    { width: 500, itemsToShow: 2 }, // this will be applied if screen size is greater than 1280px. cardsToShow will become 4.
    { width: 768, itemsToShow: 3 },
    { width: 1200, itemsToShow: 4 }
];

function Carousel() {
    return (
        <>
            <Slider
                breakPoints={responsive}
                autoSlide='true'
                showArrows='true'
                color='#000' >
                <Card >1</Card>
                <Card >2</Card>
                <Card >3</Card>
                <Card >4</Card>
                <Card >5</Card>
                <Card >6</Card>

            </Slider>
        </>
    )
}

export default Carousel

import styled from 'styled-components'

export const Card = styled.div`
    width: 100%;
    height: 200px;
    background: #000;
    color: #fff;
    font-size: 30px;
    margin-top: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
`
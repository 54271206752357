import React, { useState } from 'react'
import video from '../../videos/video.mp4';
import { Button } from '../ButtonElements';
import {
    HeroContainer,
    HeroBackground,
    VideoBackground,
    HeroContent,
    HeroHedingH1,
    HeroParagraph,
    HeroBtnWrapper,
    ArrowForward,
    ArrowRight
} from './HeroSectionElements'

const HeroSection = () => {
    const [hover, setHover] = useState(false);
    const onHover = () => {
        setHover(!hover);
    }
    return (
        <HeroContainer id='home'>
            <HeroBackground>
                <VideoBackground playsInline autoPlay loop muted src={video} type='video/mp4' />
                {/* <VideoBackground src={bg} alt="imsoft" /> */}
            </HeroBackground>
            <HeroContent>
                <HeroHedingH1> Arkitektur, utveckling och IT-ledning</HeroHedingH1>
                <HeroParagraph>
                    Vi är 100% dedikerade vår uppgift
                    </HeroParagraph>
                <HeroBtnWrapper>
                    <Button
                        to='about'
                        onMouseEnter={onHover}
                        onMouseLeave={onHover}
                        primary='true'
                        dark='true'
                        fontBig=''
                        smooth={true}
                        duration={500}
                        spy={true}
                        exact='true'
                        offset={-80}>
                        Vill du veta mer {hover ? <ArrowForward /> : <ArrowRight />}
                    </Button>
                </HeroBtnWrapper>


            </HeroContent>
        </HeroContainer>
    )
}

export default HeroSection

import React from 'react'
import Contact from '../components/Contact'
// import ScrollToTop from '../components/ScrollToTop'
import SocialMedia from '../components/SocialMedia'

const ContactPage = () => {
    return (
        <>
            {/* <ScrollToTop /> */}
            <Contact />
            <SocialMedia />
        </>
    )
}

export default ContactPage

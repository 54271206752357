import React from 'react'
import Icon1 from '../../images/svg1.svg'
import Icon2 from '../../images/svg2.svg'
import Icon3 from '../../images/svg3.svg'
import Icon4 from '../../images/svg4.svg'
import {
    ServicesContainer,
    ServicesCard,
    ServicesH1,
    ServicesH2,
    ServicesIcon,
    ServicesP,
    ServicesWrapper
} from './ServicesElements'

const Services = () => {
    return (
        <>
            <ServicesContainer id='services'>
                <ServicesH1>Våra tjänster</ServicesH1>
                <ServicesWrapper>
                    <ServicesCard>
                        <ServicesIcon src={Icon1} />
                        <ServicesH2>SYSTEMUTVECKLING</ServicesH2>
                        <ServicesP>Med över 20 års erfarenhet av systemutveckling hjälper vi dig att utveckla skräddarsydda och stabila systemlösningar.</ServicesP>
                    </ServicesCard>
                    <ServicesCard>
                        <ServicesIcon src={Icon2} />
                        <ServicesH2>ARKITEKTUR</ServicesH2>
                        <ServicesP>Våra arkitekters blick för helheten, system- och tjänstekommunikation samt säkerhet hjälper dig välja rätt lösningar.</ServicesP>
                        {/* </ServicesIcon> */}
                    </ServicesCard>
                    <ServicesCard>
                        <ServicesIcon src={Icon3} />
                        <ServicesH2>IT-LEDNING</ServicesH2>
                        <ServicesP>Våra konsulters förmåga att arbeta med It-ledning och strategi hjälper dig att leverera rätt affärsvärden i ditt utvecklingsprojekt.</ServicesP>
                        {/* </ServicesIcon> */}
                    </ServicesCard>
                    <ServicesCard>
                        <ServicesIcon src={Icon4} />
                        <ServicesH2>TEST</ServicesH2>
                        <ServicesP>Våra konsulter som jobbar med kvalitetssäkring håller ordning och reda på sina processer genom hela utvecklingsfasen.</ServicesP>
                        {/* </ServicesIcon> */}
                    </ServicesCard>
                </ServicesWrapper>
            </ServicesContainer>
        </>
    )
}

export default Services
